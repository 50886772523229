import request from '@/utils/request'
 
// 成功案例- 杰文数字

export function figureFn(params) {
    return request({
      url: '/business/pic/list',
      method: 'get',
      params
    })
  }
// 成功案例- 杰文仿真
  export function simulationFn(params) {
    return request({
      url: '/business/pic/list',
      method: 'get',
      params
    })
  }

  // 成功案例- 杰文学院
  export function collegeFn() {
    return request({
      url: '/business/xueyuan/list',
      method: 'get',
    })
  }

    // 成功案例- 杰文学院
    export function collegePrizeFn(params) {
      return request({
        url: '/business/pic/list',
        method: 'get',
        params
      })
    }