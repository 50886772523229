<template>
  <div class="success">
    <img src="../assets/success.png" alt="" class="success_img" @load="handleImgeLoad()"/>
    <!-- 杰文数字 -->
    <div class="figureJEVON" ref="page0">
      <div class="figureJEVON_title">
        <p>杰文数字</p>
        <p>JAVIN NUMERALS</p>
      </div>
      <ul class="figureJEVON_video">
        <li v-for="(item, index) in coverimg" :key="index" @click="dialogFigureFn(index)">
          <img src="../assets/push-button.png" alt="" class="push-button_img" />
          <img :src="require(`@/assets/imgsz/${index}.png`)" alt="" class="figureJEVON_video_box" />
          <p class="ellipsis figureJEVON_video_title" :title="item.remark">{{ item.remark }}</p>
        </li>
      </ul>
      <!--  -->
      <!-- 杰文数字视频弹出框 -->
      <img src="../assets/more.png" alt="" class="success_more" />
      <el-dialog :visible.sync="dialogVisibleShuzi" :before-close="SzhandleClose" width="59%">
        <video v-if="coverimg.length" :src="baseUrl + coverimg[num].pic" controls="controls" autoplay="autoplay" poster="../assets/cover.png"
          class="el-dialog_video" ref="szdialog_video"></video>
      </el-dialog>
    </div>

    <!-- 杰文智能 -->
    <div class="success_intellect" ref="page1">
      <div class="figureJEVON_title intellect">
        <p>杰文智能</p>
        <p>JIEWEN INTELLIGENCE</p>
      </div>
      <swiper class="banner_swiper" :options="bannerOption">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <div class="swiper_intellect">
            <img :src="item.bannerUrl" alt="" class="intellect_img" />
            <div class="swiper_intellect_right">
              <p>{{ item.title }}</p>
              <div class="fengexian"></div>
              <div class="swiper_briefintroduction">
                {{ item.briefintroduction }}
              </div>
              <a :href="linkList[index]" target="_bank">
                <img src="../assets/detailed.png" alt="" style="margin-top: 40px; cursor: pointer" />
              </a>

            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <!-- 杰文仿真 -->
    <div class="simulation" ref="page2">
      <div class="figureJEVON_title">
        <p>杰文仿真</p>
        <p>JAVIN SIMULATION</p>
      </div>
      <ul class="figureJEVON_video">
        <li v-for="(item, index) in simulationList" :key="item.id" @click="dialogSimulationFn(index)">
          <img src="../assets/push-button.png" alt="" class="push-button_img" />
          <img :src="require(`@/assets/imgfz/${index}.jpg`)" alt="" class="figureJEVON_video_box" />
          <p class="ellipsis figureJEVON_video_title" :title="item.remark">{{ item.remark }}</p>
        </li>
      </ul>
      <img src="../assets/more.png" alt="" class="success_more" />

      <!-- 杰文仿真视频弹出框 -->
      <el-dialog :visible.sync="dialogVisibleFangzhen" :before-close="FzhandleClose" width="59%">
        <video v-if="simulationList.length" :src="baseUrl + simulationList[numstr].pic" controls="controls" autoplay="autoplay"
          poster="../assets/cover.png" class="el-dialog_video" ref="fzdialog_video">
        </video>
      </el-dialog>

    </div>

    <!-- 杰文软件 -->
    <div class="software" ref="page3">
      <div class="figureJEVON_title">
        <p>杰文软件</p>
        <p>JIEWEN SOFTWARE</p>
      </div>
      <img src="../assets/software-icon.png" alt="" class="software_img" @load="handleImgeLoad()">
    </div>

    <!-- 杰文学院 -->
    <div class="xxxx" ref="page4">
      <div class="college" v-for="item in collegList">
        <div class="figureJEVON_title">
          <p>杰文学院</p>
          <p>JERVIN COLLEGE</p>
        </div>
        <div class="college_img">
          <ul>
            <li>
              <p>{{ item.secondPrize }}个</p>
              <p>二等奖</p>
            </li>
            <li>
              <p>{{ item.firstPrize }}个</p>
              <p>一等奖</p>
            </li>
            <li>
              <p>{{ item.thirdPrize }}个</p>
              <p>三等奖</p>
            </li>
          </ul>
          <p class="p_text">{{ item.content }}</p>
        </div>
        <ul class="college_tp">
          <li v-for="item in grandPrixList">
            <img :src=" baseUrl+item.pic" alt="">
            <div class="cup_box">
              <img src="../assets/cup-icon.png" alt="">
              <span class="cup_rank">国赛一等奖</span>
            </div>
            <p>作品名称：<span>{{ item.remark.split("&-")[0] }}</span></p>
            <p>学校名称：<span>{{ item.remark.split("&-")[1] }}</span></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { figureFn, simulationFn, collegeFn,collegePrizeFn } from '@/api/successcases'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {scrollTo} from '@/utils/scroll-to'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  name: "SuccessCases",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      // 杰文数字
      coverimg: [],
      dialogVisibleShuzi: false,
      //杰文仿真
      simulationList: [],
      dialogVisibleFangzhen: false,
      num: 0,
      numstr: 0,
      //   杰文智能
      linkList: [
        "https://www.720yun.com/t/f7vk6q89spe#scene_id=105299576",
        "https://www.720yun.com/t/d6vk6q8eg2e#scene_id=105292317"
      ],
      // banner 轮播图配置
      bannerOption: {
        loop: true,
        autoplay: true,
        spaceBetween: 30,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      //  杰文智能轮播
      bannerList: [
        {
          id: 0,
          bannerUrl: require("../assets/icon1.png"),
          briefintroduction:
            "杰文思政党建会议室结合党政文化以及精神，通过宣传文化墙进行企业精神培育和党文化融入，整体空间可以容纳22人进行同时会议，并且在会议室内，部署了沉浸式思政党建体验系统，可以结合文化教育以及信息化的素材资源进行深度体验",
          title: "杰文思政党建会议室",
        },
        {
          id: 1,
          bannerUrl: require("../assets/icon2.png"),
          briefintroduction:
            "普通话水平测试标准化站点建设总体架构由国家普通话水平智能测试系统、巡查监控系统、广播对讲系统、国家普通话水平智能测试信息采集系统、身份验证系统五部分构成。旨在推进建立科学的省、地市、县（市、区）、测试站点四级普通话水平测试考务管理与监督体系，完善全省普通话水平测试综合治理体系和决策支撑系统，实现对全省普通话测试站点的统一管理、分级管控，实现对考生、考试系统、工作人员、设备、考试过程等数据的动态管理和存储备份。",
          title: "郑州卫生健康职业学院-普通话考点项目",
        },
      ],
      // 杰文学院
      collegList: [],
       // 杰文学院-国赛
      grandPrixList:[],
      timer:null
    };
  },
  methods: {
    //  杰文数字事件弹框
    dialogFigureFn(index) {
      this.dialogVisibleShuzi = true
      this.num = index
    },
    //  杰文仿真事件弹框
    dialogSimulationFn(index) {
      this.dialogVisibleFangzhen = true
      this.numstr = index
    },
    FzhandleClose(done){
      done();
      this.$refs.fzdialog_video.pause()
    },
    SzhandleClose(done) {
      done();
      this.$refs.szdialog_video.pause()
    },
    successCaseFN() {
      //  杰文数字
      figureFn({
        type: 6
      }).then(res => {
        res.rows.forEach(item => {
          item.imgUrl = require("../assets/cover.png")
        })
        this.coverimg = res.rows
      })
      // 杰文仿真
      simulationFn({
        type: 11
      }).then(res => {
        res.rows.forEach(item => {
          item.imgUrl = require("../assets/cover.png")
        })
        this.simulationList = res.rows.slice(1, 7)
      })
      // 杰文学院
      collegeFn().then(res => {
        this.collegList = res.rows
      })
      // 杰文学院-国赛
      collegePrizeFn({type:14}).then(res=>{
       this.grandPrixList = res.rows
      })
    },
    handleImgeLoad(){
      if(this.$route.query.id!==undefined){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          scrollTo((this.$refs['page'+ (this.$route.query.id)]).offsetTop - 80,400)
        }, 500);
      }
    }
  },
  created() {
    this.successCaseFN()
  }
};
</script>

<style lang="scss" scoped>
.figureJEVON {
  width: 100%;
  height: 922px;
  background: url("../assets/figureJEVON.png");
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
}

.figureJEVON_video {
  width: 1200px;
  height: 400px;
  margin: auto;
  margin-top: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;

  li {
    width: 393px;
    height: 281px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(3, 66, 134, 0.17);
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .figureJEVON_video_box {
      width: 358px;
      height: 197px;
      border-radius: 10px;
      position: absolute;
      top: 26px;
    }

    p {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-top: 10px;
      text-align: center;
      position: absolute;
      top: 230px;
    }

    .push-button_img {
      width: 45px;
      height: 45px;
      position: absolute;
      top: -34px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
    }
  }
}

.el-dialog_video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.el-dialog__headerbtn i {
  color: red;
  font-size: 25px;
  visibility: hidden;
}

.success_more {
  position: absolute;
  bottom: 47px;
  left: 47%;
  cursor: pointer;
}

.figureJEVON_title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;

  p {
    font-size: 32px;
    font-weight: bold;
    color: #005fc6;
    margin-bottom: 10px;
  }

  p:nth-child(2) {
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }
}

.banner_img {
  width: 100%;
}

.success_intellect {
  width: 100%;
  height: 779px;
  background: url("../assets/intellect_icon.png");
  background-size: 100% 100%;
  overflow: hidden;
}

.intellect p {
  color: #ffffff;
  font-size: 32px;
}

.intellect p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
}

.swiper_intellect {
  width: 1199px;
  height: 344px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 16px 0px rgba(3, 66, 134, 0.17);
  border-radius: 10px;
  margin: auto;
  margin-top: 166px;
  position: relative;
}

.swiper_intellect_right {
  width: 460px;

  position: absolute;
  right: 70px;

  p {
    font-size: 24px;
    font-weight: bold;
    color: #005fc6;
    line-height: 30px;
    margin-top: 50px;
  }

  .swiper_briefintroduction {
    width: 100%;
    height: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-align: justify;
  }

  .fengexian {
    width: 289px;
    height: 1px;
    background: #005fc6;
    margin-top: 10px;
    margin-bottom: 22px;
  }

  .swiper_briefintroduction {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
  }
}

.intellect_img {
  width: 585px;
  height: 440px;
  position: absolute;
  left: 44px;
  top: -136px;
  border-radius: 10px;
}

.simulation {
  width: 100%;
  height: 856px;
  background: url("../assets/simulation.png");
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
}

.software {
  width: 100%;
  height: 850px;
  background: url("../assets/software.png");
  background-size: 100% 100%;
  overflow: hidden;

  .software_img {
    margin: 10px auto;
  }
}

.college {
  width: 100%;
  height: 819px;
  background: url("../assets/college.png");
  background-size: 100% 100%;
  overflow: hidden;

  .college_img {
    width: 1200px;
    height: 254px;
    background: url("../assets/win-icon.png");
    background-size: 100% 100%;
    margin: auto;
    margin-top: 30px;
    overflow: hidden;

    ul {
      margin-top: 90px;
      display: flex;
      justify-content: space-around;

      li {
        width: 256px;
        height: 76px;
        background: url("../assets/zhuanli_tuer.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 44px;
          font-weight: bold;
          color: #EFBC48;
        }

        p:nth-child(2) {
          font-size: 20px;
        }
      }

      li:nth-child(2) {
        margin-top: -30px;
      }
    }

    .p_text {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
    }
  }

  .college_tp {
    width: 1176px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cup_box {
      display: flex;
      margin-top: 30px;
      margin-bottom: 4px;

      img {
        width: 22px;
        height: 25px;
      }

      .cup_rank {
        font-size: 20px;
        font-weight: bold;
        color: #005FC6;
        line-height: 30px;
        text-indent: 10px;
      }
    }

    p {
      font-size: 16px;
      font-weight: bold;
      color: #666666;
      line-height: 30px;
    }
  }
}
</style>